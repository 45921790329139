<template>
  {{ cycleItem }}
</template>
<script>
export default {
  name: 'textCycle',
  props: {
    cycleItems: {
      type: Array,
      default: () => ['crowe'],
    },
  },
  data: () => {
    return {
      cycleItemsIndex: 0,
    }
  },
  mounted: function () {
    window.setInterval(() => {
      this.nextText()
    }, 1500)
  },
  methods: {
    nextText() {
      this.cycleItemsIndex = Math.floor(Math.random() * this.cycleItems.length)
    },
  },
  computed: {
    cycleItem() {
      return this.cycleItems[this.cycleItemsIndex]
    },
  },
}
</script>
