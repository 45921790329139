<template>
  <div class="welcome">
    <div class="title">
      <h1 class="display-1 foreground text-lowercase">
        mark.{<TextCycle :cycle-items="categories" />}
      </h1>
    </div>
    <h1 class="display-7 lead text-lowercase">
      driving digital innovation throughout a physical world
    </h1>
  </div>
</template>
<script>
import TextCycle from '@/components/text-cycle/TextCycle.vue'

export default {
  name: 'Home',
  components: {
    TextCycle,
  },
  data() {
    return {
      categories: [
        'crowe',
        'bim',
        'digital',
        'coding',
        'iot',
        'smarthome',
        'networking',
        'infrastructure',
        'gis',
      ],
    }
  },
}
</script>
<style scoped lang="scss">
.welcome {
  margin-top: 40vh;
}
</style>
